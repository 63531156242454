import { useState } from "react";
import { userStatusQuery } from "src/actions/user";
import { parseUrl } from "query-string";
import { getAppOrigin } from "src/redirect_to_app";

import FMPModal from "src/components/fmp";
import LoginModal from "src/components/login";

export default function LoginPage(props) {
  const { data, refetch: refresh_status } = userStatusQuery();
  const [fmp_open, setFMPOpen] = useState(false);

  const user = data?.success && data?.user ? data.user : null;

  const next_url =
    parseUrl(props.location.href || "").query?.next || getAppOrigin();
  if (user) {
    window.location.href = next_url;
  }

  const showFMP = () => setFMPOpen(true);
  return (
    <>
      <LoginModal
        open={true}
        show_fmp={showFMP}
        onClose={() => console.log("Not closing login forwarder")}
        refresh_status={refresh_status}
        redir_on_login={false}
      />
      <FMPModal open={fmp_open} onClose={() => setFMPOpen(false)} />
    </>
  );
}
